.NewContacts {
}

.NewContacts h1 {
  font-size: 28px;
  line-height: 31px;
  color: #0b2e53;
}

.NewContacts h3 {
  font-size: 18px;
  line-height: 33px;
  color: #0b2e53;
  margin: 0px;
  margin-bottom: 20px;
}

.newContacts td {
  width: 250px;
  max-width: 250px;
  min-width: 100px;
}

.table-container {
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 20px;
  width: 100%;
  background: #fafafa;
  border-radius: 5px;
}

h5 {
  font-weight: 600;
  margin-top: 30px;
}

.Dropdown-control {
  padding: 5px;
  width: 100%;
}

.form-control {
  padding: 10px;
}

input[type="number"],
input[type="text"],
input[type="password"],
input[type="email"] {
  min-height: 40px;
}

.NewContacts img {
  cursor: pointer;
}

.NewContacts p {
  white-space: pre-wrap;
}

.NewContacts ul {
  position: relative;
  list-style-type: none;
  padding-left: 0px;
  margin-top: 40px;
}

.NewContacts li::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #0176ff;
  transform: translateX(-50%);
}

.NewContacts li {
  list-style: none;
  width: 100%;
  position: relative;
  border-left: 1px solid #0176ff;
  padding-left: 1.5rem;
  min-height: auto;
  padding-bottom: 2.5rem;
}

.NewContacts .highlight {
  color: #0176ff;
  font-weight: 600;
}

.step {
  margin-right: 15px;
  font-weight: 300;
  padding-bottom: 5px;
}

.step.active {
  border-bottom: 5px solid #0176ff;
}

.step.done {
  text-decoration: line-through;
  opacity: 0.8;
}

#connectionRequest,
#firstFollowUpText,
#secondFollowUpText {
  min-height: 150px !important;
}

.NewContacts .btn-small {
  margin-top: 0px;
  padding: 3px 10px;
  font-size: 14px;
  max-width: 250px;
  text-align: center;
}

.NewContacts h4 {
  font-weight: 600;
  margin: 15px 0px;
  font-size: 14px !important;
  font-size: 18px;
  line-height: 21px;
  color: #0b2e53;
  margin-top: 10px;
}

.NewContacts .form-wrapper p {
  opacity: 1;
}

.red-border {
  border: 2px solid #e74c3c !important;
}

.NewContacts h6 {
  font-size: 18px;
}

.NewContacts .form-wrapper {
  padding: 0px;
  box-shadow: none;
  margin-top: 10px;
}

.NewContacts .other-btn {
  margin-top: 10px;
}
