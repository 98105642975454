.ViewCampaign {
  padding: 10px;
}

.tabs-container {
  height: 483px;
  overflow-y: scroll;
  border: 1px solid #ddd;
  padding-left: 0px;
  padding: 10px;
}

.tabs-container button {
  height: 18px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 0px;
}

.delete-btn {
  background-color: #e74c3c;
  border: none;
  color: white;
  margin: 0px 10px;
  border-radius: 5p;
}

.delete-btn:hover {
  text-decoration: none;
  color: white;
  opacity: 0.8;
}

.export-btn {
  height: 18px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 0px;
  background: #0176ff;
  color: white;
  padding: 2px 5px;
}

.export-btn:hover {
  text-decoration: none;
  color: white;
  opacity: 0.8;
}

.selected-text {
  font-size: 13px;
  color: #0176ff;
}

.ViewCampaign .nav li {
  margin-bottom: 15px;
}

.ViewCampaign .nav li.active {
  color: white;
  background-color: #0176ff;
}

.ViewCampaign .nav > li > a:focus,
.nav > li > a:hover {
  background-color: #0176ff;
  color: white;
}

.ViewCampaign .nav li.active a {
  color: white;
}

.stat-box {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 15px 0px;
}

.ViewCampaign .btn-secondary {
  margin: auto;
}
